@use '../abstracts/variables';
@use '../abstracts/mixins';

.about-us {
    background: url(../images/article/about-bg.png) no-repeat center center/cover;
    padding: 60px 0;

    .btn {
        @include mixins.font-style(19px, 600, variables.$color-blue);
        border-radius: 10px;
        padding-top: 12px;
        padding-bottom: 12px;
    }
}