.error_404 {
    background-color: #00001c;
    text-align: center;

    .heading-2 {
        margin-top: -40px;
        padding-bottom: 80px;
        position: relative;
        z-index: 1;
        box-shadow: 3px 0 15px rgba(#00001c, 0.5);
        @media (max-width: 767px) {
            margin-top: -20px;
        }
    }
}