@use '../abstracts/variables';
@use '../abstracts/mixins';

.banner {
    background: variables.$color-blue url(../images/cluvii_portal.png) no-repeat center center/cover;
    min-height: calc(100vh - 150px);
    display: grid;
    place-items: center right;
    position: relative;

    @media (max-width: 767px) {
        place-items: center center;
    }

    .banner-text {
        width: 50%;
        padding: 50px;
        background-color: rgba(variables.$color-blue, 0.9);
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        margin: 100px 0;
      
        
        @media (min-width: 1200px) {
            padding-right: 10%;
        }
        @media (min-width: 1400px) {
            padding-right: 15%;
        }
        @media (max-width: 767px) {
            width: 90%;
            border-top-right-radius: 20px;
            border-bottom-right-radius: 20px;
            padding: 30px;
        }
    }

    .join-discord {
        padding: 30px 40px;
        background-color: rgba(variables.$color-blue, 1);
        border-radius: 20px;
        text-align: center;
        position: absolute;
        left: 50%;
        bottom: -78px;
        transform: translateX(-50%);
        z-index: 1;

        @media (min-width: 768px) {
            white-space: nowrap;
        }

        @media (max-width: 767px) {
            padding: 20px;
            width: 80%;
            .heading-3 {
                font-size: 25px;
            }
        }

        .btn {
            @include mixins.font-style(25px, 700, variables.$color-blue);
            border-radius: 10px;

            &:hover {
                background-color: #fff;
                box-shadow: 0 .125rem .25rem rgba(0,0,0,.075);
            }
        }
    }
}