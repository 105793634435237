@use '../abstracts/variables';
@use '../abstracts/mixins';

.how-its-work {
    background-color: rgba(variables.$color-blue, 0.05);
    padding: 80px 0;
    text-align: center;

    .card {
        border: 0;
        box-shadow: 0px 3px 27px 0px rgba(0, 0, 0, 0.07);
        border-radius: 20px;

        .card-body {
            padding: 30px;

            h3 {
                @include mixins.font-style(30px, 700, variables.$color-blue);
                padding: 30px 0;
            }
            p {
                @include mixins.font-style(18px, 400, #161616);
                line-height: 28px;
            }
        }
    }
}