@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap");
* {
  margin: 0;
  padding: 0;
  outline: none;
  box-sizing: border-box;
}

::after, ::before {
  box-sizing: border-box;
}

h1, h2, h3, h4, h5, h6, ol, ul, p, address, strong, i {
  margin-top: 0px;
  margin-bottom: 0px;
}

ul, li {
  list-style: none;
}

a, a:hover {
  text-decoration: none;
}

body {
  font-family: "Montserrat", sans-serif;
  font-size: 19px;
  color: #000;
}

.text-blue {
  color: #01529e;
}

.text-whte {
  color: #fff;
}

.text-black {
  color: #000;
}

.fw-medium {
  font-weight: 500 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.heading-1 {
  font-size: 51px;
  font-weight: 700;
}
@media (max-width: 767px) {
  .heading-1 {
    font-size: 40px;
  }
}

.heading-2 {
  font-size: 45px;
  font-weight: 700;
}
@media (max-width: 767px) {
  .heading-2 {
    font-size: 35px;
  }
}

.heading-3 {
  font-size: 35px;
  font-weight: 700;
}
@media (max-width: 767px) {
  .heading-3 {
    font-size: 30px;
  }
}

.heading-4 {
  font-size: 28px;
  font-weight: 700;
}
@media (max-width: 767px) {
  .heading-4 {
    font-size: 22px;
  }
}

.bg-blue {
  background-color: #01529e;
}

@media (min-width: 1200px) {
  .container-xl-1170 {
    max-width: 1170px !important;
  }
}

.text-preline {
  white-space: pre-line;
}

.btn {
  border-radius: 10px;
}

.btn-border-2px {
  border-width: 2px;
}

.btn-outline-secondary {
  border-color: #b0b0b0 !important;
  color: #b0b0b0;
}
.btn-outline-secondary:hover {
  background-color: #b0b0b0 !important;
}

.btn-blue {
  background-color: #01529e;
  color: #fff;
}
.btn-blue:hover {
  background-color: #014585;
  color: #fff;
}

.btn-outline-blue {
  border-color: #01529e;
  color: #01529e;
}
.btn-outline-blue:hover {
  background-color: #01529e;
  color: #fff;
}

.article {
  padding: 150px 0;
  background: url(../images/article/article-bg.png) no-repeat center center/contain;
}
.article .bg-light-wavy {
  width: 100%;
  background: url(../images/article/light-wavy-shaped.png) no-repeat center center/cover;
  padding: 130px 0;
}
@media (max-width: 767px) {
  .article .bg-light-wavy {
    padding: 70px 0;
  }
}
@media (max-width: 767px) {
  .article article img {
    max-width: 70%;
  }
}

.about-us {
  background: url(../images/article/about-bg.png) no-repeat center center/cover;
  padding: 60px 0;
}
.about-us .btn {
  font-size: 19px;
  font-weight: 600;
  color: #01529e;
  border-radius: 10px;
  padding-top: 12px;
  padding-bottom: 12px;
}

.how-its-work {
  background-color: rgba(1, 82, 158, 0.05);
  padding: 80px 0;
  text-align: center;
}
.how-its-work .card {
  border: 0;
  box-shadow: 0px 3px 27px 0px rgba(0, 0, 0, 0.07);
  border-radius: 20px;
}
.how-its-work .card .card-body {
  padding: 30px;
}
.how-its-work .card .card-body h3 {
  font-size: 30px;
  font-weight: 700;
  color: #01529e;
  padding: 30px 0;
}
.how-its-work .card .card-body p {
  font-size: 18px;
  font-weight: 400;
  color: #161616;
  line-height: 28px;
}

.ready-to {
  text-align: center;
  padding: 80px 0;
}
.ready-to .join-discord {
  padding: 30px 40px;
  background-color: #01529e;
  border-radius: 20px;
  text-align: center;
  display: inline-block;
}
@media (min-width: 768px) {
  .ready-to .join-discord {
    white-space: nowrap;
  }
}
@media (max-width: 767px) {
  .ready-to .join-discord {
    padding: 20px;
    width: 80%;
  }
  .ready-to .join-discord .heading-3 {
    font-size: 25px;
  }
}
.ready-to .join-discord .btn {
  font-size: 25px;
  font-weight: 700;
  color: #01529e;
  border-radius: 10px;
}
.ready-to .join-discord .btn:hover {
  background-color: #fff;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}

.header .navbar {
  padding-top: 10px;
  padding-bottom: 10px;
}
.header .navbar .nav-link {
  font-size: 12px;
  color: #cdcdcd;
  width: 25px;
  height: 25px;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.1);
  line-height: 25px;
  padding: 0;
  text-align: center;
  margin-left: 20px;
  display: block;
}
.header .navbar .nav-link:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.2);
}
.header .logo {
  padding: 25px;
  text-align: center;
}
.header .logo img {
  max-width: 225px;
}

.banner {
  background: #01529e url(../images/cluvii_portal.png) no-repeat center center/cover;
  min-height: calc(100vh - 150px);
  display: grid;
  place-items: center right;
  position: relative;
}
@media (max-width: 767px) {
  .banner {
    place-items: center center;
  }
}
.banner .banner-text {
  width: 50%;
  padding: 50px;
  background-color: rgba(1, 82, 158, 0.9);
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  margin: 100px 0;
}
@media (min-width: 1200px) {
  .banner .banner-text {
    padding-right: 10%;
  }
}
@media (min-width: 1400px) {
  .banner .banner-text {
    padding-right: 15%;
  }
}
@media (max-width: 767px) {
  .banner .banner-text {
    width: 90%;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    padding: 30px;
  }
}
.banner .join-discord {
  padding: 30px 40px;
  background-color: #01529e;
  border-radius: 20px;
  text-align: center;
  position: absolute;
  left: 50%;
  bottom: -78px;
  transform: translateX(-50%);
  z-index: 1;
}
@media (min-width: 768px) {
  .banner .join-discord {
    white-space: nowrap;
  }
}
@media (max-width: 767px) {
  .banner .join-discord {
    padding: 20px;
    width: 80%;
  }
  .banner .join-discord .heading-3 {
    font-size: 25px;
  }
}
.banner .join-discord .btn {
  font-size: 25px;
  font-weight: 700;
  color: #01529e;
  border-radius: 10px;
}
.banner .join-discord .btn:hover {
  background-color: #fff;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}

.footer {
  background: #01529e url(../images/footer-bg.png) no-repeat center center/cover;
}
.footer .footer-top {
  padding: 90px 0;
}
@media (max-width: 575px) {
  .footer .footer-top {
    padding: 60px 0;
  }
}
.footer .footer-top .footer-link-heading {
  font-size: 18px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 20px;
}
.footer .footer-top ul {
  padding-left: 0;
}
.footer .footer-top ul li {
  padding-bottom: 15px;
}
.footer .footer-top ul li:last-child {
  padding-bottom: 0;
}
.footer .footer-top ul li a {
  font-size: 16px;
  color: #fff;
  display: inline-block;
  position: relative;
}
.footer .footer-top ul li a::before {
  content: "";
  width: 0%;
  height: 1px;
  background-color: #fff;
  position: absolute;
  top: 100%;
  left: 10px;
  transition: all 0.2s linear;
}
.footer .footer-top ul li a:hover::before {
  width: 90%;
}
.footer .footer-bottom {
  background-color: #00427f;
}
.footer .footer-bottom .footer-copyright {
  font-size: 12px;
  color: #fff;
  padding: 15px;
  text-align: center;
}

.error_404 {
  background-color: #00001c;
  text-align: center;
}
.error_404 .heading-2 {
  margin-top: -40px;
  padding-bottom: 80px;
  position: relative;
  z-index: 1;
  box-shadow: 3px 0 15px rgba(0, 0, 28, 0.5);
}
@media (max-width: 767px) {
  .error_404 .heading-2 {
    margin-top: -20px;
  }
}

.tos {
  background: url(../images/tos-bg.png) no-repeat center center/cover;
}
.tos .tos-card {
  background-color: #fff;
  border-radius: 20px;
  padding: 1.5rem;
  margin: 1rem 0 3rem 0;
}
@media (max-width: 767px) {
  .tos .tos-card {
    margin-top: 0;
  }
}
.tos .tos-card .agreement-title {
  color: #b0b0b0;
  font-size: 25px;
  padding-top: 10px;
}
@media (min-width: 768px) {
  .tos .tos-card .agreement-title {
    padding-left: 50px;
    padding-right: 50px;
  }
}
@media (max-width: 767px) {
  .tos .tos-card .agreement-title {
    font-size: 20px;
  }
}
.tos .tos-card .main-title {
  font-size: 50px;
  font-weight: 700;
  color: #000;
  margin: 5px 0 20px 0;
}
@media (min-width: 768px) {
  .tos .tos-card .main-title {
    padding-left: 50px;
    padding-right: 50px;
  }
}
@media (max-width: 767px) {
  .tos .tos-card .main-title {
    font-size: 35px;
  }
}
.tos .tos-content {
  padding-left: 0;
  height: 60vh;
  overflow: auto;
  /* width */
  /* Track */
  /* Handle */
}
@media (min-width: 768px) {
  .tos .tos-content {
    padding-left: 50px;
    padding-right: 50px;
  }
}
@media (max-width: 767px) {
  .tos .tos-content {
    margin-right: -1rem;
  }
}
.tos .tos-content > li {
  list-style-type: numeric;
  list-style-position: inside;
  margin-bottom: 15px;
}
.tos .tos-content > li::marker {
  font-size: 26px;
  font-weight: 700;
  color: #000;
}
@media (max-width: 767px) {
  .tos .tos-content > li::marker {
    font-size: 20px;
  }
}
.tos .tos-content > li ul li {
  list-style-type: disc;
}
.tos .tos-content > li ol.list-style-lower-alpha li {
  list-style-type: lower-alpha;
}
.tos .tos-content > li ol.list-style-numeric li {
  list-style-type: numeric;
}
.tos .tos-content.list-style-none > li {
  list-style-type: none;
}
@media (max-width: 767px) {
  .tos .tos-content p {
    font-size: 16px;
  }
}
.tos .tos-content a:hover {
  text-decoration: underline;
}
.tos .tos-content .tos-title {
  font-size: 26px;
  font-weight: 700;
  color: #000;
  display: inline-block;
  margin: 15px 0;
}
@media (max-width: 767px) {
  .tos .tos-content .tos-title {
    font-size: 20px;
  }
}
.tos .tos-content::-webkit-scrollbar {
  width: 15px;
}
@media (max-width: 767px) {
  .tos .tos-content::-webkit-scrollbar {
    width: 5px;
  }
}
.tos .tos-content::-webkit-scrollbar-track {
  border-radius: 10px;
  background: #ececec;
}
.tos .tos-content::-webkit-scrollbar-thumb {
  background: #01529e;
  border-radius: 10px;
}
.tos .tos-footer {
  padding-top: 50px;
  padding-bottom: 20px;
}
@media (min-width: 768px) {
  .tos .tos-footer {
    padding-left: 50px;
    padding-right: 50px;
  }
}

/*=== abstracts css start === */
/*===// end of abstracts css === */
/*=== base css start === */
/*===// end of base css === */
/*=== components css start === */
/*===// end of components css === */
/*=== layout css start === */
/*===// end of layout css === */
/*=== pages start === */
/*===// end of pages  === *//*# sourceMappingURL=style.css.map */