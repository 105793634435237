* {
  margin: 0;
  padding: 0;
  outline: none;
  box-sizing: border-box;
}
  
::after, ::before {
  box-sizing: border-box;
}
 
h1, h2, h3, h4, h5, h6, ol, ul, p, address, strong, i {
  margin-top: 0px;
  margin-bottom: 0px;
}
   
ul, li {
  list-style: none;
}

a, a:hover {
  text-decoration: none;
}