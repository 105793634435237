@use '../abstracts/variables';

.btn {
    border-radius: 10px;
}

.btn-border-2px {
    border-width: 2px;
}

.btn-outline-secondary {
    border-color: #b0b0b0 !important;
    color: #b0b0b0;

    &:hover {
        background-color: #b0b0b0 !important;
    }
}

.btn-blue {
    background-color: variables.$color-blue;
    color: variables.$color-white;

    &:hover {
        background-color: darken(variables.$color-blue, 5);
        color: variables.$color-white;
    }
}

.btn-outline-blue {
    border-color: variables.$color-blue;
    color: variables.$color-blue;

    &:hover {
        background-color: variables.$color-blue;
        color: variables.$color-white;
    }
}
